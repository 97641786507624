import { FunctionComponent, useCallback } from 'react';
import styled from 'styled-components';
import { LocationCard } from '../../../LocationCard';
import { Badge } from '../../../Badge';
import { Button } from '../../../ui/button/Button';
import { LocalFalconLocationSelectDto } from '../../../../types/api.types';
import { CoordinatesLocation } from '../../../../types/location.types';
import { AddLocationMode } from '..';
import { useClaimLocation, useProtectLocation } from '../../../../queries/locations.query';

type FinalReviewStepProps = {
  keywords: string[];
  location: LocalFalconLocationSelectDto;
  mode: AddLocationMode;
  newCordinates: CoordinatesLocation;
  onLicenseCreated: () => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const KeywordSubtitleContainer = styled.div`
  color: var(--global-font-color-data);
  font-size: 14px;
  font-weight: 400;
  margin: 12px 0;
  text-align: center;
  width: 100%;
`;

const KeywordsContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  text-align: center;
`;

const KeywordContainer = styled.div`
  display: inline-block;
  margin: 0 10px 10px 0;
`;

const Keyword = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const SubmitContainer = styled.div`
  align-items: center;
  color: var(--global-font-color-subtitle);
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  margin: 16px 0 0;
  row-gap: 12px;
`;

export const FinalReviewStep: FunctionComponent<FinalReviewStepProps> = ({
  keywords,
  location,
  mode,
  newCordinates,
  onLicenseCreated,
}) => {
  const { isLoading: isClaimingLocation, mutateAsync: claimLocation } = useClaimLocation();
  const { isLoading: isProtectingLocation, mutateAsync: protectLocation } = useProtectLocation();

  const onClick = useCallback(async () => {
    if (mode === 'claim') {
      await claimLocation({
        address: location.address,
        googleMapsUrl: location.googleMapsUrl,
        googlePlaceId: location.googlePlaceId,
        latitude: location.latitude,
        longitude: location.longitude,
        name: location.name,
      });
      onLicenseCreated();
      return;
    }

    if (
      newCordinates.lat.toFixed(3) === location?.latitude?.toFixed(3) &&
      newCordinates.lng.toFixed(3) === location?.longitude?.toFixed(3)
    ) {
      await protectLocation({
        address: location.isServiceAreaBusiness ? undefined : location.address,
        googleMapsUrl: location.googleMapsUrl,
        googlePlaceId: location.googlePlaceId,
        isServiceAreaBusiness: location.isServiceAreaBusiness,
        keywords,
        latitude: location.latitude,
        longitude: location.longitude,
        name: location.name,
      });
      onLicenseCreated();
    } else {
      await protectLocation({
        address: location.isServiceAreaBusiness ? undefined : location.address,
        googleMapsUrl: location.googleMapsUrl,
        googlePlaceId: location.googlePlaceId,
        isServiceAreaBusiness: location.isServiceAreaBusiness,
        keywords,
        latitude: location.latitude,
        longitude: location.longitude,
        name: location.name,
        scanOverrides: {
          address: newCordinates.address,
          latitude: newCordinates.lat,
          longitude: newCordinates.lng,
        },
      });
      onLicenseCreated();
    }
  }, [claimLocation, keywords, location, mode, newCordinates, onLicenseCreated, protectLocation]);

  return (
    <Container>
      <div>
        <LocationCard
          location={location}
          newCordinates={newCordinates}
        />
        {mode === 'protect' && <KeywordSubtitleContainer>Location Keywords</KeywordSubtitleContainer>}
      </div>
      <KeywordsContainer>
        {keywords.map((keyword) => (
          <KeywordContainer key={keyword}>
            <Badge type="normal">
              <Keyword>{keyword}</Keyword>
            </Badge>
          </KeywordContainer>
        ))}
      </KeywordsContainer>
      <SubmitContainer>
        {mode === 'protect' && <div>This will consume 1 Location License and {keywords.length} Keywords</div>}
        <Button
          fullWidth
          isLoading={isClaimingLocation || isProtectingLocation}
          onClick={onClick}
        >
          {`${mode === 'claim' ? 'Claim' : 'Protect'} Location`}
        </Button>
      </SubmitContainer>
    </Container>
  );
};
